<template>
    <div id="page-admin">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading"><span @click="click1">System</span> <span @click="click2">Admin</span><span @click="click3">&nbsp;&nbsp;&nbsp;</span></h1>
                </div>

                <div class="col-12">
                    <app-tabs v-model="tab" :tabs="tabs" :dropdown-on-tablet="true"></app-tabs>
                </div>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appTabs from '@/components/app-tabs'

export default {
    components: {
        appTabs,
    },

    data() {
        return {
            customer: {},

            clicked1: false,
            clicked2: false,
            clicked3: false,

            base_tabs: [
                {
                    id: 'providers',
                    name: 'providers',
                    title: 'Service Providers',
                },
                {
                    id: 'products',
                    name: 'products',
                    title: 'Product Manager',
                },
            ],
            pre_release_tabs: [
                {
                    id: 'services',
                    name: 'services',
                    title: 'Service Manager',
                },
                // {
                //     id: 'resources',
                //     name: 'resources',
                //     title: 'Resource Config',
                // },
            ],
        }
    },

    mounted() {
        this.clicked1 = false;
        this.clicked2 = false;
        this.clicked3 = false;
    },

    methods: {
        click1() {
            this.clicked1 = this.clicked1 ? false : true
            this.checkClicks()
            return true;
        },
        click2() {
            this.clicked2 = this.clicked2 ? false : true
            this.checkClicks()
            return true;
        },
        click3() {
            this.clicked3 = this.clicked3 ? false : true
            this.checkClicks()
            return true;
        },
        checkClicks() {
            if (this.clicked1 && this.clicked2 && this.clicked3) {
                this.$store.dispatch('setUnsupportedFeatures', { Enabled: true })
            } else {
                this.$store.dispatch('setUnsupportedFeatures', { Enabled: false })
            }
        },
    },

    computed: {
        ...mapGetters([
            'is_prerelease_mode',
        ]),
        tabs() {
            return this.is_prerelease_mode ? this.base_tabs.concat(this.pre_release_tabs) : this.base_tabs
        },
        tab: {
            get() {
                const tab = this.tabs.find(tab => this.$route.name == tab.name || this.$route.name == tab.alias)

                if (!tab) {
                    this.tab = { name: 'providers', replace: true }
                }

                return tab
            },

            set(tab) {
                this.$router.push({ name: tab.name, replace: true })
            }
        },
    },
}
</script>

<style lang="scss">
#page-admin {
    margin-top: 48px;

    h1 {
        margin-bottom: 40px;
    }

    .app-tabs {
        flex-grow: 1;
        margin-bottom: 34px;
    }
}

@media (max-width: $tablet-size) {
    #page-admin {
        h1 {
            margin-bottom: 48px;
        }
    }
}

@media (max-width: $mobile-size) {
    #page-admin {
        margin-top: 32px;
        
        h1 {
            margin-bottom: 32px;
        }
        
        .app-tabs {
            flex-grow: 0;
            margin-bottom: 16px;
        }
    }
}
</style>